@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Style the scrollbar track with a gradient that looks like a gap */
::-webkit-scrollbar-track {
  background: hsla(251.16, 40%, 80%, 1);
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: hsla(251.16, 62.7%, 20%, 1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
